import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';

const side_space = 10;

const legendData = [
  { name: "Detractors", color: "#D7443F" },
  { name: "Passives", color: "#F4B62F" },
  { name: "Promoters", color: '#33AA52' },
].reverse();

function CustomBarChart({ data, sample_size_data,   shield_data }) {

  let isGraphWave1 = false;
  let isGraphWave2 = false;
  let waveToShow;
  
  data.forEach(item => {
    // Check if any of the Wave1 properties exist
    if ('Wave 1_Detractors' in item || 'Wave 1_Passives' in item || 'Wave 1_Promoters' in item) {
        isGraphWave1 = true;
        waveToShow = "Wave 1";
    }
    // Check if any of the Wave2 properties exist
    if ('Wave 2_Detractors' in item || 'Wave 2_Passives' in item || 'Wave 2_Promoters' in item) {
        isGraphWave2 = true;
        if (waveToShow === "Wave 1") {
            waveToShow = "both";
        } else {
            waveToShow = "Wave 2";
        }
    }
});

  const CustomTick = (props) => {
    const { x, y, payload } = props;
    // Find the data point corresponding to this tick
    const tickData = sample_size_data.find(item => item.name === payload.value);
    
    if (!tickData) return null;
  
    return (
      <>
      {waveToShow !== "both" && waveToShow !== undefined &&(
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#fff" fontSize="12">
         {waveToShow}
        </text>
        <text x={0} y={12} dy={16} textAnchor="middle" fill="#fff" fontSize="12">
          (n= {waveToShow === "Wave 1" ? tickData['Wave 1'] : tickData['Wave 2']})
        </text>
  
        <text x={0} y={32} dy={16} textAnchor="middle" fill="#fff" fontSize="16">
          {payload.value}
        </text>
      </g>)}

    {waveToShow ==="both" &&(
      <g transform={`translate(${x},${y})`}>
        <text x={-30} y={0} dy={16} textAnchor="middle" fill="#fff" fontSize="12">
          Wave 1
        </text>
        <text x={-30} y={12} dy={16} textAnchor="middle" fill="#fff" fontSize="12">
          (n={tickData["Wave 1"]})
        </text>
        <text x={30} y={0} dy={16} textAnchor="middle" fill="#fff" fontSize="12">
          Wave 2
        </text>
        <text x={30} y={12} dy={16} textAnchor="middle" fill="#fff" fontSize="12">
          (n={tickData['Wave 2']})
        </text>
        <text x={0} y={32} dy={16} textAnchor="middle" fill="#fff" fontSize="16">
          {payload.value}
        </text>
      </g>)}
      </>
    );
  };
  

  return (
    <div style={{ display:'flex',  height: '100%', padding: '0px'}}>
      <ResponsiveContainer width="100%">
        <BarChart data={data} margin={{ top: 20, bottom: 50 }} formatter={(value) => `${value}%`}>
          
          <XAxis dataKey="name" tick={<CustomTick />} padding={{ right: side_space, left: side_space }} stroke='white' />
          <YAxis tickFormatter={(value) => `${value}%`} stroke='white' />

                    
          {isGraphWave1 && (
            <>
            <Bar dataKey="Wave 1_Detractors" stackId="Wave 1" fill="#D7443F" name="Wave 1 Detractors">
              <LabelList dataKey="Wave 1_Detractors" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white' }} />
            </Bar>

            <Bar dataKey="Wave 1_Passives" stackId="Wave 1" fill="#F4B62F" name="Wave 1 Passives">
              <LabelList dataKey="Wave 1_Passives" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white' }} />
            </Bar>

            <Bar dataKey="Wave 1_Promoters" stackId="Wave 1" fill="#33AA52" name="Wave 1 Promoters">
              <LabelList dataKey="Wave 1_Promoters" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white' }} />
            </Bar>
            </>
          )}
          
            {isGraphWave2 && (
            <>
              <Bar dataKey="Wave 2_Detractors" stackId="Wave 2" fill="#D7443F" name="Wave 2 Detractors">
                <LabelList dataKey="Wave 2_Detractors" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white' }} />
              </Bar>

              <Bar dataKey="Wave 2_Passives" stackId="Wave 2" fill="#F4B62F" name="Wave 2 Passives">
                <LabelList dataKey="Wave 2_Passives" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white' }} />
              </Bar>

              <Bar dataKey="Wave 2_Promoters" stackId="Wave 2" fill="#33AA52" name="Wave 2 Promoters">
                <LabelList dataKey="Wave 2_Promoters" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white' }} />
              </Bar>
            </>
            )}

        </BarChart>
      </ResponsiveContainer>
      <div style={{ 
            display: 'flex', 
            flexDirection: 'column', // Aligns children in a column
            justifyContent: 'space-between', // Spaces children with space between them
            boxSizing: 'border-box', // Ensure padding is included in the height/width
            
          }}>
            <div style={{ marginTop: '30px', alignContent: 'center', marginLeft: '15px' }}>
              {legendData.map((item, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '15px', height: '15px', backgroundColor: item.color }} />
                  <div style={{ color: 'white', marginRight: '5px', paddingLeft: '5px' }}>{item.name}</div>
                </div>
              ))}
            </div>

            <div style={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              alignItems: 'flex-end',     
            }}>
              <SmallShield 
                scale = '80%'
                fill = {shield_data.fill}
                waveTxt={shield_data.waveTxt}
              />
            </div>
          </div>

    </div>
    )};
  

export default CustomBarChart;